import {Link, useOutletContext} from 'react-router-dom';
import {useContext} from 'react';
import Context from '../Context';

const TableRow = ({title, category, deadline, entry, action, status, changeStatus, thisId, activeClass, completeClass, setAssign, setId, updated_at, created_at, AssignedBy, AssignedTo, customerNumber, ThisName, lastName, phoneNumber, thisEmail, stuff, customers, setSoloCustomer, setSoloJobDetails, Description_of_job, Intallation_type, spinner, setSpinner}) => {

  //const [tasks, setTasks, tasksBckUp, setTasksBckUp, assign, setAssign, id, setId, soloCustomer, setSoloCustomer] = useOutletContext();
  const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);

  const handleAssign = (currentStatus, currentThisId) => {
    //console.log(spinner);
    //setSpinner(true);
    //console.log(spinner);
    if(currentStatus === "Pending")
    {
      setAssign(false); setId(currentThisId);
    }
    else
    {
      //console.log(spinner);
      changeStatus(currentThisId);
     //setSpinner(false);
    }
    
  }

  const getCustomer = async(id, id2) => {

      const res = await fetch(`https://api.te-amo.co.za/public/api/customers/${id}`);
      const data = await res.json();

      const res2 = await fetch(`https://api.te-amo.co.za/public/api/tasks/${id2}`);
      const data2 = await res2.json();

      setSoloCustomer(data);
      console.log(data);

      setSoloJobDetails(data2);
      setJobId(data2.id);
      localStorage.setItem("jobId", JSON.stringify(data2.id));
      //console.log()
      //return data;
  }

	return (
		<>
            {
              stuff||customers?
            <tr className={`${activeClass ? 'taskActive' : (completeClass ? 'taskComplete' : '')} `}>
              <td scope="row">#</td>
              <td scope="row">{ThisName}</td>
              <td scope="row">{lastName}</td>
              <td>{phoneNumber}</td>
              <td scope="row">{email}</td>
            </tr>
            :
            <tr className={`${activeClass ? 'taskActive' : (completeClass ? 'taskComplete' : '')} `}>
              <td scope="row">#</td>
              <td scope="row"><Link to={`/tasks/${customerNumber}/customer_details`}  className="myNav" onClick={() => getCustomer(customerNumber, thisId)}>JB{thisId}</Link></td>
              <td scope="row">{AssignedTo}</td>
              <td>{title?title:(Description_of_job?Description_of_job:(Intallation_type?Intallation_type:''))}</td>
              <td scope="row">{created_at}</td>
              <td scope="row">{AssignedBy}</td>
              <td scope="row">{updated_at}</td>
              <td>{category}</td>
              <td><button className="badge badge-danger text-dark">{status} </button></td>
              <td><button className="btn btn-success"  disabled={completeClass? true : false}  onClick={() => handleAssign(status, thisId)} >{action} </button></td>
            </tr>
            
            }
		</>

	);
}

export default TableRow