import {NavLink, Link} from 'react-router-dom';
import Context from './Context';
import {useContext, useState} from 'react';
import OverlaySpinner from '../OverlaySpinner';

const Login = () => {

	const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);

	const [sub, setSub] = useState(false);
	const [loader, setLoader] = useState(false);

	const handleSub = async (e) => {
		e.preventDefault();
		setLoader(true);
      	let thisEmail = document.getElementById('emailField');
      	let password = document.getElementById('passField');
      	let occupationField = document.getElementById('occupationField');

		const res = await fetch('https://api.te-amo.co.za/public/api/login', {

          method: 'POST',
          headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({'email': thisEmail.value, 'password': password.value, 'occupation': occupationField.value}),

        });


        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }

        const data = await res.json();

        if(data.message === "bad creds")
        {
        	setLoader(false);
        	const incorrect = document.getElementById('incorrect');
        	incorrect.textContent = 'incorrect email or password';
			incorrect.style.color = 'red';
        }
        else{
        	setToken(data.token);
        	setName(data.user['name']);
        	setEmail(data.user['email']);
        	setMain(data.user['main']);
        	setUser(occupationField.value);

        	const loginData = {
				      'logegdin': true
				  }

        	setLoader(false);


					setLogedIn(true);
					localStorage.setItem("logedIn2", JSON.stringify(true));

					localStorage.setItem("token", JSON.stringify(data.token));
					localStorage.setItem("name", JSON.stringify(data.user['name']));
					localStorage.setItem("email", JSON.stringify(data.user['email']));
					localStorage.setItem("main", JSON.stringify(data.user['main']));
					localStorage.setItem("user", JSON.stringify(occupationField.value));

					console.log(localStorage.getItem('logedIn2'));
        }

        console.log(data);
        //console.log(data.user['email']);
        //console.log(data.token);

        
	}

	const varify = (e) => {

		if(e.target.value === '')
		{
			//console.log(e.target.value);
			let emailField = document.getElementById('emailRequired');
			let emailFieldR = document.getElementById('emailField');
			emailField.textContent = 'email is a required field';
			emailField.style.color = 'red';
			emailFieldR.style.border = '1px solid red';
		}
	}

	const varify2 = (e) => {

		if(e.target.value === '')
		{
			let passwordField = document.getElementById('passwordRequired');
			let passField = document.getElementById('passField');
			passwordField.textContent = 'password is a required field';
			passwordField.style.color = 'red';
			passField.style.border = '1px solid red';
		}
	}

	const varify3 = (e) => {

		if(e.target.value === 'none')
		{
			let occupationRequired = document.getElementById('occupationRequired');
			let occupationField = document.getElementById('occupationField');
			occupationRequired.textContent = 'Field is a required field';
			occupationRequired.style.color = 'red';
			occupationField.style.border = '1px solid red';
		}
	}

	return(
		<>
			{loader?
              <OverlaySpinner />
              :
              ''
            }
			<div className="authForms">
				<small id="incorrect"></small>
				<form method="post" onSubmit={handleSub} >

				  <div className="form-group">
					  <select className="form-control" id="occupationField" name="cat" onBlur={varify3} >
					    <option hidden value='none'>Office or field user?</option>
					    <option value='office'>Office</option>
					    <option value='field'>Field</option>
					    <option value='owner'>Main user</option>
					  </select>
					  <small id="occupationRequired"></small>
				  </div><br />

				  <label htmlFor="exampleFormControlTextarea1">Email</label>
				  <div className="form-group">
				    <input type="email" className="form-control" id="emailField" aria-describedby="emailHelp" placeholder="Email" name="task" onBlur={varify} />
				    <small id="emailRequired"></small>
				  </div><br />


				  <div className="form-group">
				    <label htmlFor="exampleFormControlTextarea1">Password</label>
				    <input type="password" className="form-control" id="passField" aria-describedby="emailHelp" placeholder="Password" name="task" onBlur={varify2} />
				    <small id="passwordRequired"></small>
				  </div><br />


				  
				  
				  <input type="submit" className="btn btn-dark" value="Sign in" disabled={sub}/>
				</form>
				<small>New user? <b><Link className='authLink' to='/register'>Create an Account</Link></b></small>
			</div>
		</>
	);
}

export default Login;