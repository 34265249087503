const DataLoaderInt = async (cat, main) =>
{
	//let data;
	
	const res3 = await fetch(`https://api.te-amo.co.za/public/api/tasks/total/status/Active?main=${main}`);
	const data3 = await res3.json();

	const res4 = await fetch(`https://api.te-amo.co.za/public/api/tasks/total/status/Completed?main=${main}`);
	const data4 = await res4.json();

	const res5 = await fetch(`https://api.te-amo.co.za/public/api/tasks/total/status/Pending?main=${main}`);
	const data5 = await res5.json();

	const res = await fetch(`https://api.te-amo.co.za/public/api/tasks/total?main=${main}`);
	const data = await res.json();

	const res6 = await fetch('https://api.te-amo.co.za/public/api/customers/total');
	const data6 = await res6.json();
	
	const returnValue = {total: data, active: data3, completed: data4, pending: data5, customers: data6};
	
	//console.log(returnValue);

	return returnValue;
}

export default DataLoaderInt; 