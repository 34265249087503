import Table from './Main/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTasks, faClock, faFile, faUsers } from '@fortawesome/free-solid-svg-icons';
import {useLoaderData, useNavigation} from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";
import Context from './Context';
import {useContext, useState, useEffect} from 'react';

function Home() {

  //const [ modal, setModal ] = useState(true);

  const [logedIn, setLogedInn, token, setToken, name, setName, email, setEmail] = useContext(Context);
     

  useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  let tasksAmount = useLoaderData();
  console.log(tasksAmount);

  return (
    <>
    <div className="contentHeader shadow-sm sticky-top">
        <div className="row">
            <div className="col-md-8">
            <h4 className="TaskContentHeading">Dashboard</h4>
            </div>

            {/*<div className="col-md-4">
            <small>Company name</small>
            </div>*/}
        </div>
    </div>

    <div className="dashboardArea">
        <p className="mt-5 welcome text-dark">Welcome
        <h4>{name}</h4>
        </p>


        
        <div className="row">
            <div className="col-md-6">
                <small className="j-tracker">Job Tracker</small>
                <div className="shadow tableCard">
                	{/* <FontAwesomeIcon icon={faTasks} className="icon" /> */}
                	

                    <div className="row">

                        <div className="col-md-3">
                            <b className="text-job">Total Jobs:</b>
                        </div>

                        <div className="col-md-2">
                        <h3>{useNavigation.state=="loading"?'Loading...':tasksAmount?.total}</h3>
                        </div>
                    </div>


                <div className="row">


                    <div className="col-4">
                        <div className="row">

                            <div className="col-lg-4">
                                <p>Open:</p>
                            </div>

                            <div className="col-lg-2">
                                <p>{useNavigation.state=="loading"?'Loading...':tasksAmount?.active}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="row">

                            <div className="col-lg-4">
                                <p>Closed:</p>
                            </div>

                            <div className="col-lg-2">
                                <p>{useNavigation.state=="loading"?'Loading...':tasksAmount?.completed}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="row">

                            <div className="col-lg-5">
                                <p>Pending:</p>
                            </div>

                            <div className="col-lg-2">
                                <p>{useNavigation.state=="loading"?'Loading...':tasksAmount?.pending}</p>
                            </div>
                        </div>
                    </div>

                </div>

                    <ProgressBar className="progressBar" completed={Math.round((tasksAmount?.completed/tasksAmount?.total)*100)} maxCompleted={100} bgColor="#000"/>
                	
                	<FontAwesomeIcon icon="fa-solid fa-bars-progress" />
                </div>
            </div>

            <div className="col-md-6">
                <small className="j-tracker">Customers</small>
                <div className="shadow tableCard">
                	<FontAwesomeIcon icon={faUsers} className="icon" />
                	<p>Total customers: <h3>{tasksAmount?.customers}</h3></p>
                </div>
            </div>
        </div>


        <div className="row">
            <small className="j-tracker">Appointments</small>
            <div className="col-md-4">
                <div className="shadow-lg tableCard">
                	<FontAwesomeIcon icon={faFile} className="icon" />
                	<p>Total Appointments: <h3>0</h3></p>
                </div>
            </div>
        </div>
        
    </div>
    </>
  );
}

export default Home;
