import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTasks, faClock, faCog } from '@fortawesome/free-solid-svg-icons';
import {Link, useOutletContext, NavLink, useParams} from 'react-router-dom';
import {useState, useEffect, useContext} from 'react';
import Tabs from './Tabs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Context from './Context';

const SoloJob = ({soloCustomer, setSoloCustomer, setSpinner, soloJobDetails}) => {

	const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);

	//const [tasks, setTasks, tasksBckUp, setTasksBckUp, assign, setAssign, id, setId, soloCustomer, setSoloCustomer] = useOutletContext();
//console.log(soloCustomer[0].Last_Name);
	//soloCustomer[0]?console.log(soloCustomer[0]):console.log('');

	const custObj = {...soloCustomer};

	const [thisName, setThisName] = useState(soloCustomer?soloCustomer[0].Name:'');
	const [latName, setLatName] = useState(soloCustomer?soloCustomer[0].Last_Name:'');
	const [thisEmail, setThisEmail] = useState(soloCustomer?soloCustomer[0].Email:'');
	const [phone, setPhone] = useState(soloCustomer?soloCustomer[0].Phone_Number:'');
	const [address, setAddress] = useState(soloCustomer?soloCustomer[0].Address:'');
	const [save, setSave] = useState(true);
	//const [taskData, setTaskData] = useState();
	const [nowTest, setNowTest] = useState();

	useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

	let { idReal } = useParams();


	useEffect(() => {
						setCusId(idReal);
						localStorage.setItem("cusId", JSON.stringify(idReal));
			       const getCustomer = async(id) => {
			       console.log('good');
			      const res = await fetch(`https://api.te-amo.co.za/public/api/customers/${idReal}`);
			      const data = await res.json();

			      //setSoloCustomer(data);
			      console.log(cusId);

			      //setSoloJobDetails(data2);
			      //return data;

			      setThisName(data[0].Name);
			      setLatName(data[0].Last_Name);
			      setThisEmail(data[0].Email);
			      setPhone(data[0].Phone_Number);
			      setAddress(data[0].Address);
			  }
			  console.log(jobId);
			  getCustomer(cusId);

    },[]);

	const submit = async (e) => {
		e.preventDefault();
		//console.log(custObj);
		setSpinner(true);
		const res = await fetch(`https://api.te-amo.co.za/public/api/customers/${cusId}/update`, {

              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({name: thisName, last: latName, phone: phone, email: thisEmail, address: address}),

          });

		  if (res.ok) {
            //throw new Error('Network response was not ok');
		  			setSpinner(false);
            toast.success('Customer updated successfully', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
          else
          {
          	setSpinner(false);
            toast.error('Customer failed to updated please check network connection or contact your service provider!!!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
          }
	}
	const thisSetName = (e) =>
	{
		setThisName(e);
		setSave(false);
	}

	const thisSetLastName = (e) =>
	{
		setLatName(e);
		setSave(false);
	}

	const thisSetEmail = (e) =>
	{
		setThisEmail(e);
		setSave(false);
	}

	const thisSetPhone = (e) =>
	{
		setPhone(e);
		setSave(false);
	}

	const thisSetAddress = (e) =>
	{
		setAddress(e);
		setSave(false);
	}

	return(
		<>
			<div className="contentHeader shadow-sm sticky-top"><h4 className="TaskContentHeading">Customer datails (edit) </h4></div>

        	<div className="maiSecNave"><Link to="/">Dashboard</Link> > <Link to="/customers">Customers</Link> ><b>{`CS${idReal}`}</b></div>

        	<div className="TableArea">

				<Tabs />

				<form className="editForm" onSubmit={submit}><br />
					<div className="form-group">
					    <label htmlFor="exampleFormControlTextarea1">Name</label>
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Name" name="task" value={thisName} onChange={(e) => thisSetName(e.target.value)} />
					</div><br />

					<div className="form-group">
					    <label htmlFor="exampleFormControlTextarea1">Last name</label>
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last name" name="task" value={latName} onChange={(e) => thisSetLastName(e.target.value)}/>
					</div><br />

					<div className="form-group">
					    <label htmlFor="exampleFormControlTextarea1">Email</label>
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" name="task" value={thisEmail} onChange={(e) => thisSetEmail(e.target.value)}/>
					</div><br />

					<div className="form-group">
					    <label htmlFor="exampleFormControlTextarea1">Phone</label>
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone" name="task" value={phone} onChange={(e) => thisSetPhone(e.target.value)}/>
					</div><br />

					<div className="form-group">
					    <label htmlFor="exampleFormControlTextarea1">Location</label>
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Location" name="task" value={address} onChange={(e) => thisSetAddress(e.target.value)} />
					</div><br />

					<input type="submit" value="Save" className="btn btn-dark" disabled={save} />
				</form>
			</div>
		</>
	)
}

export default SoloJob