import Table from './Main/Table';
import AddStuff from './addStuff';
import {useState} from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useLoaderData, useNavigation, useOutletContext, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import Scheduler from './Scheduler';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Stuff = () =>
{
	const [ modal, setModal ] = useState(true);

	const addFormHandle = async (formData) => {

          const res = await fetch('https://api.te-amo.co.za/public/api/stuff/add', {

              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(formData),
          })

          if (res.ok) {
            //throw new Error('Network response was not ok');

            const data = await res.json();

            if(data.Member_exists)
            {
                toast.error('Email already exists', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
            else{
                toast.success('Stuff member added successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
            
          }
          else
          {
            toast.error('Stuff member failed to add please check network connection!!!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
          }
          setModal(true);
      //console.log(formData);   
  }


  const customerNav = useNavigation();
  const customerOriginal = useLoaderData();

  const [ data, setData ] = useState(customerOriginal);
  const [ dataBackUp, setDataBackup ] = useState(customerOriginal);

  //console.log(customerOriginal);

  if(customerNav.state === 'loading')
  {
  	return(
  		<div className="TableArea">
        <SkeletonTheme baseColor="#dfdfdf" highlightColor="#afafaf" width="7%">
          <p>
            
            <Skeleton count={1} height="30px" width="10%"/><br /><br /><br />

            <div className="row">
              <div className="col-md-12" >
                <Skeleton count={1} height="450px" width="85%"/>
              </div>
            </div>
          </p>
        </SkeletonTheme>
      </div>
  	)
  }

	return (

    <div>
    	<div className="contentHeader shadow-sm sticky-top"><h4 className="TaskContentHeading">Stuff</h4></div>

        <div className="maiSecNave"><Link to="/" className="maiSecNaveLink">Dashboard</Link> > <b>Stuff</b></div>

    	<div className="TableArea">
	        <Table stuff={true} open={setModal} data={data} setData={setData} dataBackUp={dataBackUp} />
        </div>
    	{/*<Scheduler />*/}
        <AddStuff open={modal} close={()=>setModal(true)} submitForm={addFormHandle} />
    </div>
  );
}

export default Stuff