const OverlaySpinner = () => {

	return(
		<div className="myOverlay">
			<div className="content">
				<div className="spinner">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
			<p className="loading-text"><b>Loading...</b></p>
		</div>
	)

}

export default OverlaySpinner;