import {Link} from 'react-router-dom';
import Context from '../Context';
import {useContext, useState, useEffect} from 'react';


const SideDropMenu = () => {
	const [logedIn, setLogedInn, token, setToken, name, setName, email, setEmail] = useContext(Context);
	

	useEffect(() => {
  		const hidden = document.getElementById('hidden');
		hidden.style.display = 'none';
	}, []);

	const showMenu = (byId) => {
		const hidden = document.getElementById('hidden');
		if(hidden.style.display == 'none')
			hidden.style.display = 'block';
		else
			hidden.style.display = 'none';
	}
	function logout()
	{
		localStorage.setItem("logedIn2", JSON.stringify(false));
		setLogedInn(false);
	}
	return(

		<div>
			<div className="fixed-bottom mySideDropDown" >
	          <ul className="my-menu text-small shadow" id="hidden">
	            {/*<li><Link className="dropdown-item" to="#">Settings</Link></li>
	            <li><Link className="dropdown-item" to="#">Profile</Link></li>
	            <li><hr className="dropdown-divider" /></li>*/}
	            <li onClick={logout}><Link className="dropdown-item" to="#">Sign out</Link></li>
	          </ul>

	          <strong className="dropdown-toggle" onClick={()=>showMenu()} style={{cursor: 'pointer'}}>{name}</strong>
	          

	        </div>
		</div>

	)

}

export default SideDropMenu;